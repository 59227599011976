import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import ConnectSaleChannel from './pages/Connect-Sale-Channel'
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/connect-sale-channel/:platform" element={<ConnectSaleChannel />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
