import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import '../../App.css'

export default function Home() {
    const [isAuthorized, setIsAuthorized] = React.useState(false);
    React.useEffect(() => {
        window.addEventListener('message', receiveDataFromWeb, false);
        return () => {
            window.removeEventListener('message', receiveDataFromWeb, false);
        };
    }, []);

    const receiveDataFromWeb = (data) => {
        if (data && data.data && data.data.type === 'SENT_TO_IFRAME') {
            localStorage.setItem('tenant_id', data.data.tenant_id);
            window.location.href = data.data.url_platform
            localStorage.setItem('accessToken', data.data.accessToken)
            localStorage.setItem('tenant_domain', data.data.tenant_domain)
            setIsAuthorized(true)
        }
    }

    if (!isAuthorized) return <div className="circular"><CircularProgress sx={{ position: 'absolute', top: '45%', left: '50%' }} /></div>;

    return (<></>)
}