import axios from 'axios';

const axiosInstanceStore = axios.create({
  baseURL: process.env.REACT_APP_HOST,
});

axiosInstanceStore.interceptors.response.use(
  resp => {
    const { data, status } = resp;
    if (!status || Math.floor(status / 100) === 2) {
      if (data?.is_success) {
        const validateData = data.code === undefined ? data : data;
        return validateData;
      }
    }
    return Promise.reject(resp);
  },
  error => {
    const { response } = error;
    if (response) return Promise.reject(response);
    return Promise.reject(error);
  },
);

const request = async (
  url,
  {
    method = 'GET',
    params = null,
    contentType = 'application/json',
    data = {},
    accessToken = '',
  } = {},
) => {
  let headers = {
    'Content-Type': contentType,
    'x-source': 'seller',
  };
  headers.Authorization = `Bearer ${accessToken}`;

  return axiosInstanceStore({
    method,
    headers,
    url,
    params,
    data,
  })
};

export default request;