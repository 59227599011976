import React from 'react';
import { useLocation } from 'react-router-dom';
import request from '../../../ultils/request';
import '../../../App.css'
import { CircularProgress } from '@mui/material';

const loading = 'Đang kết nối ...'

export default function Tiktok() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const tenant_id = localStorage.getItem('tenant_id');
  const accessToken = localStorage.getItem('accessToken')
  const tenant_domain = localStorage.getItem('tenant_domain')

  const [errorMessage, setErrorMessage] = React.useState(loading)

  React.useEffect(() => {
    connectStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectStore = async () => {
    const response = await request(
      '/sales-channels-service/tiktok/grant-auth',
      {
        params: {
          auth_code: code,
          tenant_id: tenant_id,
        },
        accessToken,
      },
    )
      .then(response => response)
      .catch(error => error);

    if (response.is_success) {
      setErrorMessage('Kết nối cửa hàng thành công')
    } else if (
      response?.data?.error_code === 'already_connected_with_other_partner'
    ) {
      setErrorMessage('Cửa hàng đã kết nối với 1 tài khoản khác. Nếu bạn là chủ của cửa hàng đó, vui lòng ngắt kết nối và kết nối lại!')
    } else {
      setErrorMessage('Kết nối cửa hàng không thành công!')
    }
  };

  return (
    <div className='message'>
      <span>{errorMessage}</span>
      {errorMessage === loading ? (
        <div className="circular"><CircularProgress /></div>
      ) : 
        <div onClick={() => window.location.href = tenant_domain} className='btn'>OK</div>
      }
    </div>
  );
}
